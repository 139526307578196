// palette for Tropica Casino
//$bg-color: #1F1D1A;
//$text-color: #eee4d8;
//$primary: #339a9a;

$bg-color: #14171f;
$text-color: #ffffcc;
$primary: #ff6600;
$success: #6DF2A7;
$info: #F27649;
$danger: #F22E62;



//hamburger-menu in primary color
$navbar-dark-icon-color: $primary;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

#rivalHeaderNavAndLogoSection img {
  height: 50px;
  margin-top: -5px;
  max-width: 200px !important;
  width: auto !important;
}

.sticky-sidebar {
  .nav-link:hover {
    color: $primary !important;
    .casino-icon path {
      fill: $primary !important;
    }
  }
  .active-link {
    color: $primary !important;
    .casino-icon {
      opacity: 1;
      path {
        fill: $primary !important;
      }
    }
  }
}


#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$text-color} !important;
  }
}


